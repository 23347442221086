import {Typography} from "@mui/material";
import React from "react";

const ImageStatisticsComponent = ({statistics}) => {
    const {
        last_game_time, p25_num_steps, p50_num_steps, p75_num_steps, num_of_games,
        avg_num_steps
    } = statistics;
    return (
        <div>
            <Typography variant="body1">Num of Games: {num_of_games}</Typography>
            <Typography variant="body1">Last Game
                Time: {last_game_time ? new Date(last_game_time).toLocaleString() : 'N/A'}</Typography>
            <Typography variant="body1">25th Percentile of
                Steps: {p25_num_steps !== null ? p25_num_steps : 'N/A'}</Typography>
            <Typography variant="body1">50th Percentile of Steps
                (Median): {p50_num_steps !== null ? p50_num_steps : 'N/A'}</Typography>
            <Typography variant="body1">75th Percentile of
                Steps: {p75_num_steps !== null ? p75_num_steps : 'N/A'}</Typography>
            <Typography variant="body1">Avg
                Steps: {avg_num_steps !== null ? avg_num_steps.toFixed(2) : 'N/A'}</Typography>
        </div>
    );
};

export default ImageStatisticsComponent;
