// apiUtils.js
import {fetchAuthSession, getCurrentUser} from "aws-amplify/auth";

const getToken = async () => {
    const session = await fetchAuthSession();   // Fetch the authentication session
    return session.tokens.accessToken.toString();
};

const BASE_URL = 'https://api.bongo-ai.com';

const callAPI = async (endpoint, method, queryParams = {}, body = null, extraHeaders = {}) => {
    const url = new URL(`${BASE_URL}${endpoint}`);
    Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));
    const {username} = await getCurrentUser();

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + await getToken(),
        'username': username,
        ...extraHeaders // Merge extra headers with default headers
    };

    const response = await fetch(url.toString(), {
        method: method,
        headers: headers,
        body: body ? JSON.stringify(body) : null
    });
    const jsonResponse = await response.json();

    if (!response.ok) {
        console.error(`Failed to ${method} data.\n${JSON.stringify(jsonResponse)}`);
        throw new Error(`Failed to ${method} data.\n${JSON.stringify(jsonResponse)}`);
    }
    return jsonResponse;

};

export const callGameAPI = async (region_id, endpoint, method, queryParams = {}, body = null, extraHeaders = {}) => {
    const url = `/${region_id}/game${endpoint}`;
    return await callAPI(url, method, queryParams, body, extraHeaders);
};

export const callGameEditorAPI = async (endpoint, method, queryParams = {}, body = null, extraHeaders = {}) => {
    const url = `/game_editor${endpoint}`;
    return await callAPI(url, method, queryParams, body, extraHeaders);
};
