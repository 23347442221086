import React, {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const AutocompleteInput = ({objectName, setObjectName, style}) => {
    const [suggestions, setSuggestions] = useState([]);


    const handleChange = (e, newValue) => {
        setObjectName(newValue);

        if (!newValue) {
            setSuggestions([])
            return
        }

        // Make a request to Wikipedia API for suggestions
        fetch(`https://en.wikipedia.org/w/api.php?action=opensearch&search=${newValue}&format=json&origin=*`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setSuggestions(data[1]); // Assuming the suggestions are in the first element of the response array
            })
            .catch(error => {
                console.error('Error fetching suggestions:', error);
                setSuggestions([]);
            });
    };
    return (
        <Autocomplete
            disableClearable
            freeSolo
            autoHighlight
            style={style}
            value={objectName}
            onInputChange={handleChange}
            // onKeyDown={(e) => handleKeyDown(e)}
            options={suggestions}
            filterOptions={(options, state) =>
                options}
            renderInput={(params) =>
                <TextField {...params} label="Object Name"/>}
        />
    );
};

export default AutocompleteInput;
