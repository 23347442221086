import {callGameEditorAPI} from "./apiUtils";

const fetchRegions = async (isGameAvailable = null) => {
    try {
        const params = isGameAvailable !== null ? {is_game_available: isGameAvailable} : {};
        return await callGameEditorAPI('/regions', 'GET', params);
    } catch (error) {
        console.error('Error fetching regions:', error);
    }
};

const fetchLanguages = async () => {
    try {
        return await callGameEditorAPI('/languages', 'GET');
    } catch (error) {
        console.error('Error fetching languages:', error);
    }
};

// Export both functions
export {fetchRegions, fetchLanguages};
