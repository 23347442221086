import React, {useState} from 'react';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Rating,
    Snackbar,
    Stack,
    TextField
} from '@mui/material';
import {callGameEditorAPI} from './apiUtils';

const RatingDialog = ({open, onClose, gameId, language}) => {
    const [stars, setStars] = useState(null);
    const [feedbackText, setFeedbackText] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const resetFields = () => {
        setStars(null);
        setFeedbackText('');
    };

    const handleSubmit = async () => {
        const feedbackData = {
            client_type: 'WEB',
            game_uuid: gameId,
            rating: stars,
            feedback_text: feedbackText,
        };

        const headers = {
            'Preferred-Language': language,
        };

        try {
            await callGameEditorAPI('/ratings', 'POST', {}, feedbackData, headers);
            setSnackbarMessage('Thank you for your feedback!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            handleClose();
        } catch (error) {
            setSnackbarMessage('Error submitting feedback. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            console.error('Error submitting feedback:', error);
        }
    };

    const handleClose = () => {
        resetFields();
        onClose();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle textAlign="center" sx={{marginTop: '4px'}}>Rate Game</DialogTitle>
                <DialogContent sx={{marginInline: '20px'}}>
                    <Stack spacing={3} alignItems="center">
                        <Rating
                            name="game-stars"
                            value={stars}
                            size="large"
                            onChange={(event, newValue) => {
                                setStars(newValue >= 1 ? newValue : 1);
                            }}
                        />
                        <TextField
                            margin="dense"
                            label="Feedback"
                            type="text"
                            sx={{minWidth: '300px'}}
                            multiline
                            rows={2}
                            value={feedbackText}
                            onChange={(event) => setFeedbackText(event.target.value)}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        disabled={stars === null}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default RatingDialog;
