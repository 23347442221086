import React, {useEffect, useState} from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function ImageCropper(props) {
    const {imageToCrop: initialImageToCrop, onImageCropped} = props;
    const [imageToCrop, setImageToCrop] = useState(initialImageToCrop);
    const [imageRef, setImageRef] = useState();
    const [cropConfig, setCropConfig] = useState({
        unit: "%",
        width: 100,
        aspect: 1,
    });

    useEffect(() => {
        setImageToCrop(initialImageToCrop);
    }, [initialImageToCrop]);

    useEffect(() => {
        if (imageRef && cropConfig.width && cropConfig.height) {
            cropImage(cropConfig);
        }
    }, [imageRef, cropConfig]);

    async function cropImage(crop) {
        if (imageRef && crop.width && crop.height) {
            const croppedImage = await getCroppedImage(
                imageRef,
                crop,
                "croppedImage.jpeg"
            );
            onImageCropped(croppedImage);
        }
    }

    function getCroppedImage(sourceImage, cropConfig, fileName) {
        const canvas = document.createElement("canvas");

        // Use the natural width and height of the image to calculate the scale
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;

        // Set the canvas width and height based on the crop dimensions at the original resolution
        canvas.width = cropConfig.width * scaleX;
        canvas.height = cropConfig.height * scaleY;

        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    reject(new Error("Canvas is empty"));
                    return;
                }

                blob.name = fileName;
                const croppedImageUrl = window.URL.createObjectURL(blob);
                resolve(croppedImageUrl);
            }, "image/jpeg");
        });
    }

    return (
        <div>
            <ReactCrop
                src={imageToCrop}
                crop={cropConfig}
                onImageLoaded={(image) => setImageRef(image)}
                onChange={(cropConfig) => setCropConfig(cropConfig)}
                crossorigin="anonymous"
                minHeight={30}
                minWidth={30}
                imageStyle={{maxHeight: "350px"}}
                disabled={imageToCrop === null}
            />
        </div>
    );
}

ImageCropper.defaultProps = {
    onImageCropped: () => {
    },
};

export default ImageCropper;
