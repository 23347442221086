import React, {useEffect, useState} from 'react';
import './ImageSuggestions.css';
import {callGameEditorAPI} from "./apiUtils"; // Import CSS file for styling

const ImageSuggestions = ({objectName}) => {
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        if (objectName.trim() !== '') {
            fetchSuggestions(objectName);
        }
    }, [objectName]);

    const fetchSuggestions = async (objectName) => {
        try {
            const data = await callGameEditorAPI(`/images/${objectName}/soundalike?limit=4`);
            setSuggestions(data);
        } catch (error) {
            console.error('Error fetching image suggestions:', error);
        }
    };

    return (
        <div className="image-list">
            {suggestions.map((similarImage) => (
                <div key={similarImage.id} className="image-item">
                    <a href={`/edit-image/${similarImage.id}`}>
                        <img src={similarImage.internal_processed_image_url} alt={similarImage.object_name}/>
                        <p>
                            {similarImage.object_name}
                        </p>
                    </a>
                </div>

            ))}
        </div>
    );
};

export default ImageSuggestions;
