import React, {useState} from 'react';
import './ManageRegion.css';

const UpdateRegionModal = ({
                               isOpen, onClose, onAddToRegion, onDeleteFromRegion, selectedImages,
                               regions
                           }) => {
    const [selectedRegionInput, setSelectedRegionInput] = useState('IL');

    const handleAddToRegion = () => {
        onAddToRegion(selectedRegionInput);
        onClose();
    };

    const handleDeleteFromRegion = () => {
        onDeleteFromRegion(selectedRegionInput);
        onClose();
    };

    return (
        isOpen && (
            <div className="modal-manage-region">
                <div className="modal-content">
                    <div>
                        <label>
                            <h3>Region:</h3>
                            <select
                                value={selectedRegionInput}
                                onChange={(e) => setSelectedRegionInput(e.target.value)}
                                className="select-region" // Add this class for styling
                            >
                                {regions.map((region) => (
                                    <option key={region.region_code} value={region.region_code}>
                                        {region.region_name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div>
                        <h3 style={{margin: '4px'}}>{selectedImages.length} Selected Images:</h3>
                        <div className="selected-images-container">
                            <ul className="selected-images-list">
                                {selectedImages.map((image) => (
                                    <li key={image.id}>
                                        <b>{image.id}</b> - {image.object_name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <button onClick={handleAddToRegion} className="button-primary">
                            Add to Region
                        </button>
                        <button onClick={handleDeleteFromRegion} className="button-secondary">
                            Remove from Region
                        </button>
                        <button onClick={onClose} className="button-close">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};

export default UpdateRegionModal;
