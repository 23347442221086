// ImageUpload.js
import React, {useEffect, useState} from 'react';
import WikiImageList from "./WikiImageList";
import 'react-image-crop/dist/ReactCrop.css';
import ImageCropper from "./ImageCropper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import ImageSuggestions from "./ImageSuggestions";
import AutocompleteInput from "./AutocompleteWikipedia";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {callGameEditorAPI} from "./apiUtils";
import {fetchRegions} from "./globalApiRequests";

const ImageUpload = () => {


    useEffect(() => {
        document.title = "Upload Image"
        const getRegions = async () => {
            const fetchedRegions = await fetchRegions();
            setRegions(fetchedRegions);
        };
        getRegions();

    }, []);


    const [imageUrl, setImageUrl] = useState('');

    const [imageToCrop, setImageToCrop] = useState(undefined);
    const [croppedImage, setCroppedImage] = useState(undefined);

    const [objectName, setObjectName] = useState('');
    const [objectSegment, setObjectSegment] = useState('people');

    const [regions, setRegions] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);


    const onUploadFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                const image = reader.result;
                setImageToCrop(image);
                setImageUrl('')
            });
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    const onUploadUrl = () => {

        if (imageUrl.trim() !== '') {
            onUploadFileFromURL(imageUrl);
        }
    };
    const onImageClicked = (selectedImageUrl) => {
        if (selectedImageUrl.trim() !== '') {
            setImageUrl(selectedImageUrl)
            onUploadFileFromURL(selectedImageUrl);
        }
    };

    const onUploadFileFromURL = (imageUrl) => {
        // Make a request to fetch the image data
        fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
                // Convert the blob to a data URL
                const reader = new FileReader();

                reader.addEventListener("load", () => {
                    const image = reader.result;
                    setImageToCrop(image);
                });

                reader.readAsDataURL(blob);
            })
            .catch(error => {
                console.error("Error fetching image from URL:", error);
            });
    };
    const handleCheckboxChange = (event) => {
        const {value, checked} = event.target;
        setSelectedRegions((prev) => {
            if (checked) {
                // Add selected region code to the list
                return [...prev, value];
            } else {
                // Remove unselected region code from the list
                return prev.filter((code) => code !== value);
            }
        });
    };
    const onCropClick = async () => {
        try {
            setImageToCrop(croppedImage);

            const queryParams = {
                'file_name': 'croppedImage.jpeg',
                'object_name': objectName.trim(),
                'object_segment': objectSegment,
                'source_url': imageUrl.trim(),
            };
            const body = {
                region_codes: selectedRegions
            }

            const uploadUrlJson = await callGameEditorAPI('/images/upload_url', 'POST', queryParams,
                body)

            const uploadUrl = uploadUrlJson.url; // Assuming the URL is under the 'url' key in the JSON
            const blob = await fetch(croppedImage).then((res) => res.arrayBuffer()).then(buffer => new Blob([buffer]));

            // Create a FormData object and append the Blob
            const formData = new FormData();

            Object.entries(uploadUrlJson.fields).forEach(([key, value]) => {
                formData.append(key, value);
            });

            formData.append('file', blob, croppedImage.name); // Replace 'cropped_image.jpg' with the desired file name


            // Upload the FormData to the server using the obtained upload URL
            const uploadResponse = await fetch(uploadUrl, {
                method: 'POST',
                body: formData,
            });

            if (uploadResponse.ok) {
                console.log('Cropped image uploaded successfully.');


                alert('Image uploaded successfully');

                window.location.reload();

            } else {
                console.error('Failed to upload cropped image.');
                alert('Failed to upload image. Please try again.');

            }


        } catch (error) {
            console.error('Error cropping image:', error);
            alert('Error cropping image. Please try again.');
        }
    };


    return (
        <div style={{display: 'flex'}}>
            <div style={{width: '30%', maxWidth: '30%', textAlign: 'center', flex: '1', marginTop: '55px'}}>
                <h2 style={{marginTop: '22px'}}>Wiki Images</h2>
                <WikiImageList name={objectName} onImageClick={onImageClicked}/>
            </div>
            <div style={{
                textAlign: 'center', flex: '1', width: '55%', marginRight: '40px'
            }}>
                <h1 style={{minHeight: '40px', marginLeft: '10%'}}>Upload Image</h1>
                <div style={{display: "flex", width: '100%'}}>
                    <div style={{width: '30%'}}>
                        <FormControl fullWidth>
                            <InputLabel id="segment-label">Segment</InputLabel>
                            <Select
                                labelId="segment-label"
                                label="Segment"
                                value={objectSegment}
                                onChange={(e) => setObjectSegment(e.target.value)}>
                                <MenuItem value="people">People</MenuItem>
                                <MenuItem value="animals">Animals</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{width: '70%'}}>
                        <AutocompleteInput objectName={objectName} setObjectName={setObjectName}
                                           style={{width: '75%', marginLeft: '5%'}}/>

                    </div>
                </div>
                <div style={{display: "flex", width: '100%'}}>
                    <div style={{width: '30%'}}>
                        <FormGroup>
                            <FormLabel component="legend" sx={{marginTop: '15px'}}>Regions</FormLabel>
                            {regions.map((region) => (
                                <FormControlLabel
                                    key={region.region_code}
                                    control={
                                        <Checkbox
                                            value={region.region_code}
                                            checked={selectedRegions.includes(region.region_code)}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label={region.region_name}
                                />
                            ))}
                        </FormGroup>
                    </div>
                    <div style={{width: '70%'}}>
                        <div style={{height: '350px', marginRight: '10%', marginTop: '10px'}}>
                            <ImageCropper
                                key={imageToCrop}
                                imageToCrop={imageToCrop}
                                onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
                            />
                        </div>
                    </div>
                </div>
                <div style={{
                    display: "flex", width: '100%', flexDirection: 'column', alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div className="upload-button">
                        <button onClick={onCropClick}
                                disabled={!objectName || !imageToCrop}>
                            <p>
                                Upload Image
                            </p>
                            <FontAwesomeIcon icon={faUpload} style={{fontSize: '30px', padding: '8px'}}/>
                        </button>
                    </div>
                    <input type="file" accept="image/*" onChange={onUploadFile}/>
                    <div style={{display: 'flex', alignItems: 'center', width: '80%', justifyContent: 'center'}}>
                        <input
                            type="text"
                            placeholder="Enter Image URL"
                            value={imageUrl}
                            onChange={(e) => setImageUrl(e.target.value)}
                        />
                        <button onClick={onUploadUrl} style={{marginRight: '8px'}}>Retrieve Image</button>
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex', justifyContent: 'center', width: '15%', marginRight: '10px',
                maxHeight: '800px', overflow: 'auto'
            }}>
                <div style={{textAlign: 'center'}}>
                    <h2>Suggestions</h2>
                    <ImageSuggestions objectName={objectName}/>
                </div>
            </div>

        </div>
    );
};

export default ImageUpload;
