import React, {useEffect, useRef, useState} from 'react';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {callGameAPI, callGameEditorAPI} from './apiUtils';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RefreshIcon from '@mui/icons-material/Refresh';
import UndoIcon from '@mui/icons-material/Undo';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import ChooseNameDialog from "./ChooseNameDialog";
import {useSearchParams} from "react-router-dom";
import RateReviewIcon from '@mui/icons-material/RateReview';
import RatingDialog from "./RatingDialog";
import {fetchRegions} from "./globalApiRequests";
import generateChallenge from "./GenerateChallenge";
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import {ChallengeInfoDialog} from "./ChallengesInfo";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Leaderboard from "./Leaderboard";

function PlayGame() {

    const leftButtonRef = useRef(null);
    const rightButtonRef = useRef(null);
    const upButtonRef = useRef(null);
    const downButtonRef = useRef(null);
    const enterButtonRef = useRef(null);
    const [region, setRegion] = useState(null);
    const [regions, setRegions] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();

    const [gameData, setGameData] = useState(null);
    const [language, setLanguage] = useState('en');

    const [initialCandidateId, setInitialCandidateId] = useState(searchParams.get('initialCandidate'));
    const [challengeCode, setChallengeCode] = useState(null);
    const [challengesInfoDialogOpen, setChallengesInfoDialogOpen] = useState(false);


    const [openDialog, setOpenDialog] = useState(false);
    const [undoGameData, setUndoGameData] = useState(null);
    const [imagesPath, setImagesPath] = useState([]);
    const [stepsInfo, setStepsInfo] = useState([]);
    const [challengeInfo, setChallengeInfo] = useState(null);
    const [imageStatistics, setImageStatistics] = useState({});
    const [gameInProgress, setGameInProgress] = useState(false);
    const [challengeInProgress, setChallengeInProgress] = useState(false);
    const [chooseNameDialogOpen, setChooseNameDialogOpen] = useState(false);
    const [ratingDialogOpen, setRatingDialogOpen] = useState(false);
    const [cheatsEnabled, setCheatsEnabled] = useState(false);


    useEffect(() => {
        const updateChallengeInfo = async () => {
            const searchParamsChallengeCode = searchParams.get('challengeCode')

            if (searchParamsChallengeCode) {
                setChallengeCode(searchParamsChallengeCode);
                await getChallengeDetails(searchParamsChallengeCode);

            }
        }
        const handleKeyDown = (event) => {
            console.log(event.key)
            if (event.key === 'ArrowLeft' && leftButtonRef.current) {
                leftButtonRef.current.click();
                event.preventDefault();
            } else if (event.key === 'ArrowRight' && rightButtonRef.current) {
                rightButtonRef.current.click();
                event.preventDefault();
            } else if (event.key === 'ArrowUp' && upButtonRef.current) {
                upButtonRef.current.click();
                event.preventDefault();
            } else if (event.key === 'ArrowDown' && downButtonRef.current) {
                downButtonRef.current.click();
                event.preventDefault();
            } else if (event.key === 'Enter' && enterButtonRef.current) {
                enterButtonRef.current.click();
                event.preventDefault();
            }
        };

        fetchRegions(true).then(r => {
            if (r) {
                setRegions(r);
                setRegion(r[0].region_code);
            }
        });

        updateChallengeInfo();


        document.title = "Play Game";

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleChallengeCodeChange = async (code) => {
        setSearchParams({
            ...searchParams,
            challengeCode: code
        })
        await setChallengeCode(code);
    };

    const getChallengeDetails = async (code) => {
        try {
            const response = await callGameEditorAPI(`/challenges/${code}`,
                'GET', {is_return_statistics: true})

            setChallengeInfo(response)
            setRegion(response.region_code)
            return response

        } catch (error) {
            alert(error)
        }


    }

    const handlePlayGameClick = async () => {

        const gameRegion = challengeCode ? (await getChallengeDetails(challengeCode)).region_code : region;
        try {
            const data = await callGameAPI(gameRegion, '/start', 'POST', {}, {
                client_type: 'WEB',
                first_candidate_id: initialCandidateId ? initialCandidateId : null,
                challenge_code: challengeCode ? challengeCode : null
            }, {'Preferred-Language': language});
            setGameInProgress(true);
            setChallengeInProgress(challengeCode !== "" && challengeCode !== null);
            setUndoGameData(null);
            setGameData(data);
        } catch (error) {
            console.error('Error starting a new game:', error);
            alert(`Error starting new game: ${error}`);
        }
    };

    const handleRefreshClick = async () => {
        try {
            await callGameAPI(region, '/config/refresh', 'POST');
        } catch (error) {
            console.error('Error refreshing game config:', error);
        }
    };

    const handleImageClick = async (selection) => {
        if (!gameInProgress)
            return;

        const playStepUrl = `/${gameData.game_id}/play/${gameData.step_number}`;

        try {
            const data = await callGameAPI(region, playStepUrl, 'POST', {}, {
                selection_type: selection
            }, {'Preferred-Language': language});

            gameData.selection = selection;

            setUndoGameData(gameData);
            setGameData(data);

            if (challengeInProgress && challengeInfo.target_image.id === data.first_candidate.id) {
                await handleFinishGame(challengeInfo.target_image.id);
                await getChallengeDetails(challengeCode);
            }

        } catch (error) {
            console.error('Error during game play:', error);
        }
    };

    const handleFinishGame = async (bongo_candidate_id,
                                    bongo_object_name = null) => {
        const finishGameUrl = `/${gameData.game_id}/finish`;
        const body = bongo_candidate_id
            ? {bongo_candidate_id: bongo_candidate_id}
            : {bongo_object_name: bongo_object_name};

        try {
            const data = await callGameAPI(region, finishGameUrl, 'POST', {},
                body,
                {'Preferred-Language': language});

            if (bongo_candidate_id) {
                handleBongoClick(data.images_path, data.steps_info, data.image_statistics);
            }
            setChooseNameDialogOpen(false);
            setGameInProgress(false);
        } catch (error) {
            console.error('Error during finish game', error);
        }
    };


    const handleBongoClick = (images_path, steps_info, image_statistics) => {
        setImagesPath(images_path);
        setStepsInfo(steps_info);
        setImageStatistics(image_statistics);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleUndoClick = () => {
        if (undoGameData) {
            setGameData(undoGameData);
            setUndoGameData(null);
        }
    };
    const handleGiveUpClick = () => {
        setChooseNameDialogOpen(true);
    };

    const renderImagePair = () => {
        if (!gameData || !gameData.first_candidate) {
            return null;
        }

        return (
            <div style={{textAlign: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px'}}>
                    <ImageComponent
                        candidate={gameData.first_candidate}
                        handleClick={() => handleImageClick('first')}
                        handleFinishGame={handleFinishGame}
                        gameInProgress={gameInProgress}
                        buttonRef={leftButtonRef}
                        isChallenge={challengeInProgress}
                        cheatsEnabled={cheatsEnabled}
                    />
                    <div style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px',
                        flexDirection: 'column', textAlign: 'center'
                    }}>
                        <p>Step Number: <b>{gameData.step_number}</b></p>

                        <Tooltip title="None">
                            <IconButton
                                color="secondary"
                                aria-label="None selection"
                                variant="contained"
                                onClick={() => handleImageClick('none')}
                                disabled={!gameInProgress}
                                ref={upButtonRef}>
                                <PsychologyAltIcon sx={{fontSize: '50px'}}/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Undo">
                            <IconButton aria-label="undo-step" onClick={handleUndoClick}
                                        disabled={!undoGameData || !gameInProgress}
                                        sx={{marginTop: '20px'}}
                                        ref={downButtonRef}>
                                <UndoIcon fontSize="large"/>
                            </IconButton>
                        </Tooltip>

                        <div style={{width: 150}}>
                            <div style={{height: '50px'}}>
                                {
                                    undoGameData && <img
                                        src={undoGameData.first_candidate.image_url}
                                        alt={undoGameData.first_candidate.title}
                                        style={{
                                            height: 45, width: 45, marginRight: '4px',
                                            border: undoGameData.selection === 'first' ? '4px solid dodgerblue' : '2px solid black',
                                        }}
                                    />
                                }
                                {
                                    undoGameData && <img
                                        src={undoGameData.second_candidate.image_url}
                                        alt={undoGameData.second_candidate.title}
                                        style={{
                                            height: 45, width: 45, marginLeft: '4px',
                                            border: undoGameData.selection === 'second' ? '4px solid dodgerblue' : '3px solid black'
                                        }}
                                    />
                                }
                            </div>
                            <div style={{marginTop: '20px'}}>
                                <Tooltip title="Give Up">
                                    <IconButton aria-label="give-up" onClick={handleGiveUpClick}
                                                disabled={!gameInProgress}>
                                        <FlagOutlinedIcon fontSize="large"/>
                                    </IconButton>
                                </Tooltip>

                                <div style={{marginTop: '10px'}}>
                                    <Tooltip title="Rate Review">
                                        <IconButton aria-label="rate-review" onClick={() => setRatingDialogOpen(true)}>
                                            <RateReviewIcon fontSize="large"/>
                                        </IconButton>
                                    </Tooltip>
                                </div>

                            </div>
                        </div>


                    </div>

                    <ImageComponent
                        candidate={gameData.second_candidate}
                        handleClick={() => handleImageClick('second')}
                        handleFinishGame={handleFinishGame}
                        gameInProgress={gameInProgress}
                        buttonRef={rightButtonRef}
                        isChallenge={challengeInProgress}
                        cheatsEnabled={cheatsEnabled}
                    />
                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                        <DialogTitle textAlign="center">Game Finished</DialogTitle>
                        <DialogContent>
                            <Accordion>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    expandIcon={<ExpandMoreIcon/>}>
                                    <Typography>Image Statistics</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ImageStatisticsComponent statistics={imageStatistics}/>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <Typography>Images Path</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {imagesPath.map((candidate, index) => (
                                        <div key={index} style={{marginBottom: '20px', textAlign: 'center'}}>
                                            <Typography variant="h6"
                                                        sx={{marginBottom: '5px'}}>{`${index + 1}. ${candidate.title}`}</Typography>
                                            <img
                                                src={candidate.image_url}
                                                alt={candidate.title}
                                                style={{width: '60%', height: 'auto'}}
                                            />
                                        </div>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    expandIcon={<ExpandMoreIcon/>}>
                                    <Typography>Steps Info</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <StepsInfoComponent stepsInfo={stepsInfo}/>
                                </AccordionDetails>
                            </Accordion>
                            {
                                challengeInProgress || (
                                    <Box display="flex" justifyContent="center" marginTop="16px">
                                        <Button variant="contained" color="primary"
                                                onClick={async () => {
                                                    const challengeCode = await generateChallenge(gameData.game_id, region);
                                                    await handleChallengeCodeChange(challengeCode);
                                                    await getChallengeDetails(challengeCode);
                                                }}>
                                            Generate Challenge
                                        </Button>
                                    </Box>
                                )
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <ChooseNameDialog
                        open={chooseNameDialogOpen}
                        onClose={() => setChooseNameDialogOpen(false)}
                        onSubmit={(name) => handleFinishGame(null, name)}
                    />
                    <RatingDialog
                        open={ratingDialogOpen}
                        onClose={() => setRatingDialogOpen(false)}
                        gameId={gameData.game_id}
                        language={language}
                    />
                </div>
                {
                    challengeInProgress && (
                        <img
                            src={gameData.challenge_target_candidate.image_url}
                            alt="Challenge Target"
                            style={{
                                width: '100px',
                                height: '100px',
                                objectFit: 'contain'
                            }}
                        />
                    )
                }
                {
                    challengeInProgress && gameInProgress && (
                        <>
                            <p>Target Visual Score: {gameData.challenge_target_candidate.visual_score_rank}#
                                - {gameData.challenge_target_candidate.visual_score.toFixed(4)}</p>
                            <p>Target Probability: {gameData.challenge_target_candidate.probability_rank}#
                                - {gameData.challenge_target_candidate.probability.toFixed(4)}</p>
                        </>
                    )
                }
                <p>Max Visual Score: {gameData.max_visual_score.toFixed(4)}</p>
                <p>Visual Score Threshold: {gameData.visual_score_threshold.toFixed(4)}</p>
                <p>Valid Candidates: {gameData.num_of_valid_candidates}</p>

                {
                    gameInProgress || (
                        <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}
                                disabled={gameInProgress || openDialog}
                                sx={{marginTop: '10px'}}>
                            Reopen Dialog
                        </Button>
                    )

                }

            </div>
        );
    };


    const ImageStatisticsComponent = ({statistics}) => {
        const {
            last_game_time, num_of_games, avg_steps, median_num_steps,
            current_percentile
        } = statistics;

        return (
            <div>
                <Typography variant="body1">Num of Games: {num_of_games}</Typography>
                <Typography variant="body1">Current Steps: {gameData.step_number}</Typography>
                <Typography variant="body1">Last Game
                    Time: {last_game_time ? new Date(last_game_time).toLocaleString() : 'N/A'}</Typography>
                <Typography variant="body1">Median
                    Steps: {median_num_steps !== null ? median_num_steps : 'N/A'}</Typography>
                <Typography variant="body1">Avg Steps: {avg_steps !== null ? avg_steps.toFixed(2) : 'N/A'}</Typography>
                <Typography variant="body1">Better
                    Than: {current_percentile !== null ? current_percentile : 'N/A'}%</Typography>
            </div>
        );
    };
    const StepsInfoImageCandidate = ({candidate, isSelected}) => {

        const effectStyle = candidate.selection_effect !== null ? {
            color: candidate.selection_effect > 0 ? 'limegreen' : 'red',
            fontWeight: isSelected ? 'bold' : 'normal'

        } : null;

        const formattedEffect = candidate.selection_effect !== null
            ? candidate.selection_effect > 0
                ? `+${candidate.selection_effect.toFixed(1)}%`
                : `${candidate.selection_effect.toFixed(1)}%`
            : null;

        return (
            <div style={{
                padding: '10px',
                borderRadius: '5px',
                border: isSelected ? '8px solid dodgerblue' : '3px solid black',
                height: '100%'

            }}>
                <img
                    src={candidate.image_url}
                    alt={candidate.title}
                    style={{width: '100%', height: 'auto'}}
                />
                <Typography> <b>{candidate.title}</b> </Typography>
                <Typography>{`Distance: ${candidate.distance_to_bongo.toFixed(1)}`}</Typography>
                {formattedEffect && (
                    <Typography style={effectStyle}>{`Effect: ${formattedEffect}`}</Typography>
                )} <Typography>{`VS Rank: ${candidate.visual_score_rank}`}</Typography>
                <Typography>{`Prob Rank: ${candidate.probability_rank}`}</Typography>
            </div>
        );
    };

    const StepsInfoComponent = ({stepsInfo}) => {
        return (
            <div>
                {stepsInfo.map((step, index) => (
                    <div key={index} style={{marginBottom: '10px', textAlign: 'center'}}>
                        <Typography variant="h6" sx={{marginBottom: '5px'}}>{`Step ${index + 1}`}</Typography>
                        <Typography>{`Valid Candidates: ${step.num_of_valid_candidates}`}</Typography>
                        <div style={{marginBottom: '5px'}}>
                            {step.challenge_target_state && (
                                <>
                                    <div>
                                        Target Visual
                                        Score: {step.challenge_target_state.visual_score.toFixed(2)} {step.challenge_target_state.visual_score_rank}#
                                    </div>
                                    <div> Target
                                        Probability: {(100 * step.challenge_target_state.probability).toFixed(2)}% {step.challenge_target_state.probability_rank}#
                                    </div>

                                </>
                            )}
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <StepsInfoImageCandidate
                                    candidate={step.first_candidate}
                                    isSelected={step.selection_type === 'first'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StepsInfoImageCandidate
                                    candidate={step.second_candidate}
                                    isSelected={step.selection_type === 'second'}
                                />
                            </Grid>
                        </Grid>
                        {index < stepsInfo.length - 1 && <Divider style={{marginTop: '10px'}}/>}
                    </div>
                ))}
            </div>
        );
    };
    return (
        <div style={{display: 'grid', gridTemplateColumns: '1fr 4fr 1fr'}}>
            <div style={{textAlign: 'center', width: 340}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15%'}}>
                    <h2>Game Config</h2>
                    <div style={{marginLeft: '4px', marginTop: '4px'}}>
                        <Tooltip title="Start New Game">
                            <IconButton aria-label="start-game" onClick={handlePlayGameClick}
                                        disabled={!(region || challengeCode)}
                                        ref={enterButtonRef}>
                                <PlayArrowIcon fontSize="large"/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Refresh Game Config">
                            <IconButton aria-label="refresh-game" onClick={handleRefreshClick}
                                        disabled={!region}>
                                <RefreshIcon fontSize="large"/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div style={{marginBottom: '10px'}}>
                    <FormControl sx={{m: 1, width: 120}}>
                        <InputLabel>Language</InputLabel>
                        <Select
                            value={language}
                            onChange={handleLanguageChange}
                            label="Language"
                        >
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="he">Hebrew</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, width: 150}}>
                        <InputLabel shrink={region}>Region</InputLabel>
                        <Select
                            disabled={challengeCode}
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}
                            label="Region">
                            {regions.map((region) => (
                                <MenuItem key={region.region_code} value={region.region_code}>
                                    {region.region_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div style={{marginBottom: '10px'}}>
                    <TextField
                        type="number"
                        inputProps={{min: 0, style: {textAlign: 'center'}}}
                        label="Initial Candidate ID"
                        value={initialCandidateId}
                        onChange={(e) => setInitialCandidateId(e.target.value)}
                        variant="outlined"
                        disabled={challengeCode}
                    />
                </div>
                <div style={{marginBottom: '10px', marginTop: '10'}}>
                    <TextField
                        sx={{width: 200}}
                        inputProps={{min: 0, style: {textAlign: 'center'}}}
                        label="Challenge Code"
                        value={challengeCode}
                        onChange={async (e) => {
                            await handleChallengeCodeChange(e.target.value)
                        }}
                        InputLabelProps={{shrink: challengeCode}}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title="Load">
                                        <IconButton edge="end" color="primary"
                                                    onClick={async () => await getChallengeDetails(challengeCode)}>
                                            <SendIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Clear">
                                        <IconButton edge="end" color="primary"
                                                    onClick={async () => {
                                                        await handleChallengeCodeChange("");
                                                        setChallengeInfo(null)
                                                    }}>
                                            <ClearIcon/>
                                        </IconButton>

                                    </Tooltip>

                                </InputAdornment>
                            ),
                        }}
                    />
                    <Tooltip title="Active Challenges">
                        <IconButton
                            size="large"
                            onClick={() => setChallengesInfoDialogOpen(true)}
                            sx={{
                                fontSize: '2rem'
                            }}
                        >
                            <EmojiEventsIcon
                                sx={{fontSize: 'inherit'}}/> {/* Ensure icon scales according to the button size */}
                        </IconButton>
                    </Tooltip>
                    <ChallengeInfoDialog
                        open={challengesInfoDialogOpen}
                        onClose={() => setChallengesInfoDialogOpen(false)} // Inline function to close the dialog
                        region_code={region}
                        onPlayChallenge={async (code) => {
                            setChallengeCode(code);
                            setChallengesInfoDialogOpen(false);
                            await getChallengeDetails(code);
                        }}
                    />
                </div>
                {
                    challengeInfo !== null && (
                        <>
                            <Typography variant="h5" component="h3" sx={{flexGrow: 1}}>
                                Challenge: {challengeInfo.target_image.object_name}
                            </Typography>
                            <Tooltip title="Enable challenge cheats" arrow>
                                <Switch
                                    checked={cheatsEnabled}
                                    onChange={() => {
                                        setCheatsEnabled(!cheatsEnabled)
                                    }}
                                    color="primary"
                                    inputProps={{'aria-label': 'cheats toggle'}}
                                />
                            </Tooltip>                            <p>Last Game
                            Time: {new Date(challengeInfo.statistics.last_game_time).toLocaleString()}</p>
                            <p>Successful
                                Games: {challengeInfo.statistics.num_of_successful_games} / {challengeInfo.statistics.num_of_games}</p>
                            <p>Average Steps: {challengeInfo.statistics.avg_steps}</p>
                            <p>Successful
                                Players: {challengeInfo.statistics.num_of_successful_players} / {challengeInfo.statistics.num_of_players}</p>
                            <Leaderboard challengeInfo={challengeInfo}/>
                        </>
                    )
                }

            </div>
            <div>
                {renderImagePair()}
            </div>
            <div style={{
                maxHeight: '800px',
                overflow: 'auto'
            }}>
                {gameData?.top_vs_candidates.map((candidate) => (
                    <div key={candidate.id} style={{
                        display: 'grid',
                        border: '1px solid #ddd',
                        padding: '10px',
                        marginBottom: '10px',
                        textAlign: 'center'
                    }}>
                        <div style={{marginBottom: '8px'}}>
                            <strong>{candidate.title}</strong>
                        </div>
                        <div style={{marginBottom: '8px'}}>
                            <img src={candidate.image_url} alt={candidate.title}
                                 style={{maxWidth: '100px', maxHeight: '100px'}}/>
                        </div>
                        <div>Visual Score: {candidate.visual_score.toFixed(4)} {candidate.visual_score_rank}#</div>
                        <div>Probability: {candidate.probability.toFixed(4)} {candidate.probability_rank}#</div>
                    </div>
                ))}
            </div>


        </div>
    );
}

const ImageComponent = ({
                            candidate, handleClick, handleFinishGame, gameInProgress,
                            buttonRef, isChallenge, cheatsEnabled
                        }) => {

    const [isClicked, setIsClicked] = useState(false);

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    };

    const imageStyle = {
        width: '285px',
        height: '285px',
        objectFit: 'contain'
    };
    const handleBtnClick = () => {
        setIsClicked(true);

        handleClick();


        // Perform other actions
        setTimeout(() => {
            setIsClicked(false)
        }, 100);
    };


    return (
        <div style={containerStyle}>
            <h3>{candidate.title}</h3>
            <Button variant="text" size="small"
                    ref={buttonRef}
                    onClick={handleBtnClick}
                    disabled={!gameInProgress}
                    style={{
                        transform: isClicked ? 'scale(1.05)' : 'scale(1)',
                        transition: 'transform 0.15s ease-in-out'
                    }}>
                <img
                    src={candidate.image_url}
                    alt={candidate.title}
                    style={imageStyle}
                />
            </Button>
            {
                isChallenge || (
                    <Button
                        variant="contained"
                        style={{marginTop: '25px'}}
                        onClick={() => handleFinishGame(candidate.id)}
                        color="primary"
                        disabled={!gameInProgress}>
                        Bongo
                    </Button>
                )
            }
            <div>
                <p>Probability: {candidate.probability.toFixed(4)} {candidate.probability_rank}#</p>
                <p>Visual Score: {candidate.visual_score.toFixed(4)} {candidate.visual_score_rank}#</p>
                {cheatsEnabled && candidate.selection_effect !== null &&
                    <p>Effect: {candidate.selection_effect.toFixed(1)}%</p>}
            </div>
        </div>
    );
};

export default PlayGame;
