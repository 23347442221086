// ImageGallery.js

import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBorderAll, faBroom, faEdit, faMousePointer, faRefresh, faTrash} from '@fortawesome/free-solid-svg-icons';
import UpdateRegionModal from "./ManageRegion";
import {CircularProgress, Pagination, Tooltip} from "@mui/material";
import {callGameEditorAPI} from "./apiUtils";
import {fetchLanguages, fetchRegions} from "./globalApiRequests";


const ImageGallery = () => {
    const [isAddToRegionModalOpen, setAddToRegionModalOpen] = useState(false);
    const [gridColumns, setGridColumns] = useState(4);
    const [gridRows, setGridRows] = useState(2);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedNotRegion, setSelectedNotRegion] = useState('');
    const [selectedSegment, setSelectedSegment] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [deleteImageId, setDeleteImageId] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedSortBy, setSelectedSortBy] = useState('last_modified');
    const [selectedOrderBy, setSelectedOrderBy] = useState('DESC')
    const [regions, setRegions] = useState([])
    const [languages, setLanguages] = useState([])


    useEffect(() => {
            document.title = 'Gallery'
            const fetchData = async () => {
                try {
                    setLoading(true);

                    // Construct the URL dynamically based on the selectedSegment
                    const urlParams = {
                        page: currentPage,
                        page_size: gridRows * gridColumns,
                        search_term: searchTerm,
                        language_id: selectedLanguage,
                        region_id: selectedRegion,
                        not_region_id: selectedNotRegion,
                        sort_by: selectedSortBy,
                        order_by: selectedOrderBy
                    };

                    if (selectedSegment !== '') {
                        urlParams.append('segment', selectedSegment);
                    }


                    const data = await callGameEditorAPI("/images", "GET", urlParams)
                    setImages(data.data);
                    setTotalPages(data.page_count);
                    setTotalItems(data.total_count);
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching images:', error);
                    setLoading(false);
                }
                setLoading(false);
            };
            const updateGrid = () => {
                // Update the grid based on user-selected columns and rows
                // You might have additional logic here based on your requirements
                // For simplicity, let's assume gridColumns and gridRows directly control the layout
                const galleryContainer = document.querySelector('.gallery-container');
                if (galleryContainer) {
                    galleryContainer.style.gridTemplateColumns = `repeat(${gridColumns}, 1fr)`;
                }
            };


            updateGrid();
        fetchRegions().then(r => setRegions(r))
        fetchLanguages().then(l => setLanguages(l))
            fetchData();

            setRefresh(false);

        }, [refresh, gridColumns, gridRows, currentPage, searchTerm, selectedLanguage,
            selectedRegion, selectedNotRegion, selectedSegment, selectedSortBy, selectedOrderBy]
    )
    ;

    const openAddToRegionModal = () => {
        setAddToRegionModalOpen(true);
    };

    const closeAddToRegionModal = () => {
        setAddToRegionModalOpen(false);
    };


    const handleAddToRegion = async (regionId) => {
        try {

            await callGameEditorAPI(`/regions/${regionId}/add_images`, "POST", {},
                {image_ids: selectedImages.map(image => image.id)}
            )
            alert('Images added successfully to region')
        } catch (error) {
            console.error('Error adding images to region:', error);
            alert('Error adding images to region');
        }
    };

    const handleDeleteFromRegion = async (regionId) => {
        try {
            await callGameEditorAPI(`/regions/${regionId}/remove_images`,
                "DELETE", {}, {image_ids: selectedImages.map(image => image.id)})

            alert('Successfully removed images from region');
        } catch (error) {
            console.error('Error removing images from region:', error);
            alert('Failed to remove images from region');
        }
    };

    const openEditTab = (imageId) => {
        window.open(`/edit-image/${imageId}`, '_blank');
    };


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset current page to 1 when search term changes

    };
    const handleRegionChange = (event) => {
        if (event.target.value === '' && selectedSortBy === 'weight')
            setSelectedSortBy('id')
        setSelectedRegion(event.target.value);
        setCurrentPage(1); // Reset current page to 1 when region changes
    };
    const handleNotRegionChange = (event) => {
        setSelectedNotRegion(event.target.value);
        setCurrentPage(1); // Reset current page to 1 when region changes
    };
    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
        if (searchTerm.trim() !== '') {
            setCurrentPage(1);
        }
    };

    const handleSegmentChange = (event) => {
        setSelectedSegment(event.target.value);
        setCurrentPage(1);
    };

    const openDeleteConfirmation = (imageId) => {
        setDeleteImageId(imageId);
    };

    const closeDeleteConfirmation = () => {
        setDeleteImageId(null);
    };

    const handleDeleteImage = async () => {
        try {

            // Set up the API endpoint for deleting the image
            await callGameEditorAPI(`/images/${deleteImageId}`, "DELETE")


            setImages((prevImages) => prevImages.filter((image) => image.id !== deleteImageId));
            setSelectedImages(selectedImages.filter((image) => image.id !== deleteImageId))

            setTotalItems(totalItems - 1)

            closeDeleteConfirmation();
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handleRefresh = () => {
        setRefresh(true);
    };

    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                closeDeleteConfirmation();
            } else if (event.key === 'ArrowLeft') {
                setCurrentPage(Math.max(currentPage - 1, 1));
                event.preventDefault();
            } else if (event.key === 'ArrowRight') {
                setCurrentPage(Math.min(currentPage + 1, totalPages));
                event.preventDefault();
            }
        };

        // Add event listener for escape key press
        window.addEventListener('keydown', handleEscapeKey);

        // Remove event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleEscapeKey);
        };
    }, [currentPage, totalPages]);

    const handleImageSelection = (selectedImage) => {
        setSelectedImages((prevSelectedImages) => {
            if (isImageSelected(selectedImage)) {
                return prevSelectedImages.filter((image) => image.id !== selectedImage.id);
            } else {
                return [...prevSelectedImages, selectedImage];
            }
        });
    };

    function isImageSelected(image) {
        return selectedImages.some(selectedImage => selectedImage.id === image.id);
    }

    const selectPage = () => {
        const imagesOnPage = images.filter(image => !isImageSelected(image));
        setSelectedImages(prevSelectedImages => [...prevSelectedImages, ...imagesOnPage]);
    };

    return (

        <div className="image-gallery">
            <div className="left-menu">
                <div className="menu-section">
                    <h2 style={{
                        margin: '4px',
                        fontSize: '22px'
                    }}>Filters</h2>
                    <div className="filter">
                        <label>
                            Search Term:
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchTermChange}/>
                        </label>
                        <label>
                            Region:
                            <select
                                style={{marginLeft: '4px'}}
                                value={selectedRegion}
                                onChange={handleRegionChange}>
                                <option value="">All</option>
                                {regions.map((region) => (
                                    <option key={region.region_code} value={region.region_code}>
                                        {region.region_name}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label>
                            <b>Not</b> in region:
                            <select
                                style={{marginLeft: '4px'}}
                                value={selectedNotRegion}
                                onChange={handleNotRegionChange}>
                                <option value=""></option>
                                {regions.map((region) => (
                                    <option key={region.region_code} value={region.region_code}>
                                        {region.region_name}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label>
                            Segment:
                            <select
                                style={{marginLeft: '4px'}}
                                value={selectedSegment}
                                onChange={handleSegmentChange}>
                                <option value="">All</option>
                                <option value="Animals">Animals</option>
                                <option value="People">People</option>
                            </select>
                        </label>
                    </div>
                </div>
                <div className="menu-section">
                    <h2 style={{
                        margin: '4px',
                        fontSize: '22px'
                    }}>View</h2>
                    <div className="filter">
                        <label>
                            Language:
                            <select
                                style={{marginLeft: '4px'}}
                                value={selectedLanguage}
                                onChange={handleLanguageChange}>
                                <option value="">None</option>
                                {languages.map((lang) => (
                                    <option key={lang.language_code} value={lang.language_code}>
                                        {lang.language_name}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label style={{display: 'flex'}}>
                            Sort:
                            <select
                                style={{marginRight: '4px', marginLeft: '4px'}}
                                value={selectedSortBy}
                                onChange={(e) => setSelectedSortBy(e.target.value)}>
                                <option value="id">Id</option>
                                <option value="object_name">Name</option>
                                <option value="last_modified">Modified</option>
                                <option value="num_of_games">Games</option>
                                <option value="weight" disabled={selectedRegion === ''}>Weight</option>
                            </select>
                            <select
                                value={selectedOrderBy}
                                onChange={(e) => setSelectedOrderBy(e.target.value)}>
                                <option value="ASC">&#9650;</option>
                                <option value="DESC">&#9660;</option>
                            </select>
                        </label>
                        <label style={{display: 'flex'}}>
                            Columns:
                            <input
                                style={{width: '50px', marginLeft: '10px'}}
                                type="number"
                                value={gridColumns}
                                onChange={(e) => setGridColumns(parseInt(e.target.value, 10))}
                            />
                        </label>
                        <label style={{display: 'flex'}}>
                            Rows:
                            <input
                                style={{width: '50px', marginLeft: '10px'}}
                                type="number"
                                value={gridRows}
                                onChange={(e) => setGridRows(parseInt(e.target.value, 10))}
                            />
                        </label>
                        <div className="upload-button">
                            <button onClick={handleRefresh}>
                                <div style={{marginTop: '8px'}}>
                                    Refresh
                                </div>
                                <FontAwesomeIcon icon={faRefresh} style={{fontSize: '24px', padding: '6px'}}/>
                            </button>

                        </div>

                        <div
                            style={{display: 'flex'}}>
                            <div className="upload-button"
                                 style={{paddingTop: '12px'}}>
                                <button onClick={openAddToRegionModal}
                                        disabled={!selectedImages.length}
                                        style={{paddingTop: '10px'}}>
                                    Select Images
                                    <FontAwesomeIcon icon={faMousePointer}
                                                     style={{fontSize: '24px', padding: '8px'}}/>
                                </button>
                            </div>
                            <div>
                                <div className="upload-button">
                                    <button onClick={selectPage}>
                                        Select Page
                                        <FontAwesomeIcon icon={faBorderAll}
                                                         style={{fontSize: '20px', padding: '4px'}}/>
                                    </button>
                                </div>
                                <div className="upload-button">
                                    <button onClick={() => setSelectedImages([])} disabled={!selectedImages.length}>
                                        Clear
                                        <FontAwesomeIcon icon={faBroom} style={{fontSize: '20px', padding: '4px'}}/>
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div style={{textAlign: 'center'}}>Selected Items: {selectedImages.length}</div>

                        <div style={{marginTop: '10px', textAlign: 'center'}}>
                            Total: <b>{totalItems}</b>

                        </div>
                    </div>

                </div>

            </div>
            <div className="image-gallery-content">

                <div className="pagination-container" style={{
                    display: 'flex', marginTop: '10px',
                    marginBottom: '10px', justifyContent: 'center', marginRight: '10%'
                }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        size="large"
                        onChange={(e, v) => handlePageChange(v)}
                    />
                </div>
                {
                    loading ? <div
                            style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px',
                                marginRight: '10%'
                            }}>
                            <CircularProgress/>
                        </div>
                        : <div className="gallery-container">
                            {images.map((image) => (
                                <div key={image.id} className="image-card">
                                    <Tooltip title={image.id} placement='top' enterDelay={250} enterNextDelay={250}>
                                        <img
                                            src={image.internal_processed_image_url}
                                            alt={image.object_name}
                                            onClick={() => openEditTab(image.id)}
                                        />
                                    </Tooltip>
                                    <div className="image-card-overlay">
                                        <div>
                                            <b style={{margin: '4px'}}>{image.object_name}</b>
                                            {image.name_in_language && (
                                                <><br/> {image.name_in_language}</>
                                            )}
                                            {image.weight_in_region && (
                                                <><br/>{selectedRegion} weight: <b>{image.weight_in_region}</b></>
                                            )}
                                            {image.num_of_games !== null && (
                                                <><br/>Games: <b>{image.num_of_games}</b></>
                                            )}
                                        </div>
                                    </div>
                                    <div className="image-card-buttons">
                                        <button onClick={() => openEditTab(image.id)}>
                                            <FontAwesomeIcon icon={faEdit} style={{fontSize: '16px'}}/>
                                        </button>
                                        <input
                                            type="checkbox"
                                            checked={isImageSelected(image)}
                                            onChange={() => handleImageSelection(image)}
                                            style={{
                                                minWidth: '22px',
                                                minHeight: '22px'
                                            }}
                                        />
                                        <button onClick={() => openDeleteConfirmation(image.id)}>
                                            <FontAwesomeIcon icon={faTrash} style={{fontSize: '16px'}}/>
                                        </button>

                                    </div>
                                </div>

                            ))}
                        </div>

                }


            </div>
            {deleteImageId && (
                <div className="delete-confirmation">
                    <p>Are you sure you want to delete this image?</p>
                    <div>
                        <button onClick={handleDeleteImage}>Yes</button>
                        <button onClick={closeDeleteConfirmation}
                                style={{backgroundColor: 'gray'}}>No
                        </button>
                    </div>
                </div>
            )}

            <UpdateRegionModal
                isOpen={isAddToRegionModalOpen}
                onClose={closeAddToRegionModal}
                onAddToRegion={handleAddToRegion}
                onDeleteFromRegion={handleDeleteFromRegion}
                selectedImages={selectedImages}
                regions={regions}
            />
        </div>
    );
};

export default ImageGallery;
