import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import {AppBar, Avatar, Box, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography} from '@mui/material';
import {CloudUpload, PlayArrow} from '@mui/icons-material';
import GridViewIcon from '@mui/icons-material/GridView';
import {fetchAuthSession, getCurrentUser, signOut} from 'aws-amplify/auth';
import {Hub,} from 'aws-amplify/utils';


import ImageGallery from "./ImageGallery";
import ImageDetails from "./ImageDetails";
import ImageUpload from "./ImageUpload";
import PlayGame from "./PlayGame";
import {Amplify} from 'aws-amplify';
import awsExports from './aws-exports';

import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {useEffect, useState} from "react";
import React from ".";


Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID,
            region: awsExports.REGION,
            userPoolId: awsExports.USER_POOL_ID,
            userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
            loginWith: {
                oauth: {
                    redirectSignIn: [process.env.REACT_APP_REDIRECT_SIGN_IN],
                    redirectSignOut: [process.env.REACT_APP_REDIRECT_SIGN_OUT],
                    domain: "bongo.auth.eu-central-1.amazoncognito.com",
                    providers: [], // Google
                    scopes: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
                    responseType: "code",
                },
            },
            userAttributes: {
                nickname: {}
            }

        }

    }
})


function App() {

    useEffect(() => {
        const updateUser = async () => {
            try {
                const {username} = await getCurrentUser()


                const {tokens} = await fetchAuthSession();

                console.log(tokens.idToken.payload)
                setCurrentUser({
                    username: username,
                    name: tokens.idToken.payload.nickname
                })

            } catch (error) {
                console.log("Error fetching current user:", error);
            }
        };

        updateUser()

        Hub.listen('auth', ({payload}) => {
            if (payload.event === 'signedIn') {
                updateUser();
            }
        })

        // return () => Hub.remove('auth', updateUser)
    }, []);

    const settings = ['Copy Token', 'Logout'];

    const copyAccessToken = async () => {
        try {
            const session = await fetchAuthSession();   // Fetch the authentication session
            console.log('Access Token:', session.tokens.accessToken.toString());
            console.log('ID Token:', session.tokens.idToken.toString());
            await navigator.clipboard.writeText(`bearer ${session.tokens.accessToken.toString()}`)
        } catch (e) {
            console.log(e);
        }
    };

    const handleMenuItemClick = async (clickedSetting) => {
        console.log("Clicked setting:", clickedSetting);
        switch (clickedSetting) {
            case "Copy Token":
                await copyAccessToken()
                break;
            case "Logout":
                await signOut()
                break;
            // Add more cases as needed
            default:
                console.log("No action is specified for setting " + clickedSetting)
                break;
        }
        handleCloseUserMenu()
    };

    const [anchorElUser, setAnchorElUser] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };


    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Authenticator loginMechanisms={['email']} variation="modal">
            {({signOut, user}) => (
                <div>
                    <Router>
                        <AppBar position="fixed" sx={{width: '15%', left: 0, height: '65px'}}>
                            <Toolbar sx={{justifyContent: 'center'}}>
                                <Tooltip title="Gallery">
                                    <IconButton component={Link} to="/" edge="start" color="inherit"
                                                aria-label="gallery"
                                                sx={{marginInline: '4px'}}>
                                        <GridViewIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Upload">
                                    <IconButton component={Link} to="/upload-image" edge="start" color="inherit"
                                                target="_blank" aria-label="upload" sx={{marginInline: '4px'}}>
                                        <CloudUpload/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Play">
                                    <IconButton component={Link} to="/play-game" edge="start" color="inherit"
                                                target="_blank" aria-label="play" sx={{marginInline: '4px'}}>
                                        <PlayArrow/>
                                    </IconButton>
                                </Tooltip>

                                <Box sx={{flexGrow: 0, marginLeft: '5px'}}>
                                    <Tooltip title="Open settings">
                                        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                            <Avatar/>
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{mt: '45px'}}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}>
                                        {currentUser && (
                                            <MenuItem disabled>
                                                <Typography
                                                    textAlign="center">{currentUser.name ?
                                                    currentUser.name : currentUser.username}</Typography>
                                            </MenuItem>
                                        )}

                                        {settings.map((setting) => (
                                            <MenuItem key={setting} onClick={() => handleMenuItemClick(setting)}>
                                                <Typography textAlign="center">{setting}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>


                            </Toolbar>
                        </AppBar>
                        <div style={{marginTop: '5px'}}>
                            <Routes>
                                <Route path="/" element={<ImageGallery/>}/>
                                <Route path="/edit-image/:image_id" element={<ImageDetails/>}/>
                                <Route path="/upload-image" element={<ImageUpload/>}/>
                                <Route path="/play-game" element={<PlayGame/>}/>
                            </Routes>
                        </div>

                    </Router>
                </div>
            )}
        </Authenticator>


    );
}

export default App;
