import React, {useEffect, useState} from 'react';

const WikiImageList = ({name, onImageClick}) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        async function fetchImages() {
            if (!name) {
                return
            }

            console.log("Fetching images: " + name);

            try {
                const response = await fetch(
                    `https://en.wikipedia.org/w/api.php?action=query&format=json&generator=images&titles=${name}&prop=imageinfo&redirects=1&origin=*&gimlimit=20&iiprop=url|mime|thumbmime|mediatype|bitdepth`
                );
                const data = await response.json();
                const pages = data.query.pages;
                const imageList = [];
                for (const key in pages) {
                    if (Object.hasOwnProperty.call(pages, key)) {
                        const page = pages[key];
                        if (page.imageinfo && page.imageinfo[0]) {
                            const imageUrl = page.imageinfo[0].url;
                            if (imageUrl.toLowerCase().endsWith('.jpg') ||
                                imageUrl.toLowerCase().endsWith('.jpeg') ||
                                imageUrl.toLowerCase().endsWith('.png')) {
                                imageList.push(imageUrl);
                            }
                        }
                    }
                }
                setImages(imageList);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        }

        fetchImages();
    }, [name]);


    return (
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {images.map((imageUrl, index) => (
                <div key={index} style={{marginRight: '10px', marginBottom: '10px'}}>
                    <img
                        src={imageUrl}
                        alt={imageUrl}
                        style={{maxHeight: '120px', maxWidth: '120px', 'cursor': 'pointer'}}
                        onClick={() => onImageClick(imageUrl)}
                    />
                </div>
            ))}
        </div>
    );
};

export default WikiImageList;
