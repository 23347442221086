// ImageDetails.js
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import 'react-image-crop/dist/ReactCrop.css';
import ImageCropper from "./ImageCropper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import WikiImageList from "./WikiImageList";
import {Alert, Box, IconButton, MenuItem, Select, Snackbar, Tooltip, Typography} from "@mui/material";
import AutocompleteInput from "./AutocompleteWikipedia";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import {callGameEditorAPI} from "./apiUtils";
import ImageStatisticsComponent from "./ImageStatistics";
import TranslateIcon from '@mui/icons-material/Translate';
import TranslateDialog from "./TranslateDialog";

const ImageDetails = () => {
    const {image_id} = useParams();

    const [imageDetails, setImageDetails] = useState({});
    const [imageUrl, setImageUrl] = useState('');
    const [objectName, setObjectName] = useState('');
    const [objectSegment, setObjectSegment] = useState('');

    const [similarImages, setSimilarImages] = useState([]);
    const [distantImages, setDistantImages] = useState([]);

    const [imageToCrop, setImageToCrop] = useState(null);
    const [croppedImage, setCroppedImage] = useState(undefined);
    const [originalImageUlr, setOriginalImageUlr] = useState('');
    const [translateDialogOpen, setTranslateDialogOpen] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');

    const handleEditClick = async () => {
        try {
            const requestBody = {
                object_name: objectName,
                object_segment: objectSegment
            };

            await callGameEditorAPI(`/images/${image_id}`, 'PATCH', {}, requestBody)

            setImageDetails({...imageDetails, object_name: objectName, object_segment: objectSegment})

            // Handle success (if needed)
            console.log('Image info updated successfully');
        } catch (error) {
            console.error('Error updating image info:', error);
            // Handle error (if needed)
        }
    };


    const handleCloseAlert = () => {
        setAlertOpen(false);
    };

    const displayAlert = (severity, message) => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);

        setTimeout(() => {
            setAlertOpen(false);
        }, 3000);
    };

    const onUploadFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                const image = reader.result;
                setImageToCrop(image);
                setImageUrl('')
            });
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    const onUploadUrl = () => {
        if (imageUrl) {
            onUploadFileFromURL(imageUrl);
        }
    };

    const onUploadFileFromURL = (imageUrl) => {
        // Make a request to fetch the image data
        fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
                // Convert the blob to a data URL
                const reader = new FileReader();

                reader.addEventListener("load", () => {
                    const image = reader.result;
                    // Now you have the image data in a data URL
                    setImageToCrop(image);
                });

                reader.readAsDataURL(blob);
            })
            .catch(error => {
                console.error("Error fetching image from URL:", error);
            });
    };
    const onImageClicked = (selectedImageUrl) => {
        if (selectedImageUrl.trim() !== '') {
            setImageUrl(selectedImageUrl)
            onUploadFileFromURL(selectedImageUrl);
        }
    };


    useEffect(() => {
        const fetchImageDetails = async () => {
            if (image_id == null)
                return;

            try {
                const data = await callGameEditorAPI(`/images/${image_id}`, 'GET');
                setImageDetails(data);

                setOriginalImageUlr(data.internal_processed_image_url)
                setObjectName(data.object_name);
                document.title = data.object_name;
                setObjectSegment(data.object_segment);

            } catch (error) {
                console.error('Error fetching image details:', error);
            }
        };

        const fetchLookalikeImages = async () => {
            if (image_id == null) return;

            try {
                const data = await callGameEditorAPI(`/images/${image_id}/lookalike`, 'GET');
                setSimilarImages(data.similar_images);
                setDistantImages(data.distant_images);
            } catch (error) {
                console.error('Error fetching lookalike images:', error);
            }
        };

        fetchImageDetails();
        fetchLookalikeImages();


    }, [image_id]);

    const onCropClick = async () => {
        try {

            const queryParams = {
                'file_name': 'croppedImage.jpeg',
                'object_name': imageDetails.object_name,
                'object_segment': imageDetails.object_segment,
                'source_url': imageUrl,
                'image_id': image_id
            };

            const uploadUrlJson = await callGameEditorAPI(`/images/${image_id}/update_url`, "POST", queryParams)

            const uploadUrl = uploadUrlJson.url; // Assuming the URL is under the 'url' key in the JSON
            const blob = await fetch(croppedImage).then((res) => res.arrayBuffer()).then(buffer => new Blob([buffer]));

            // Create a FormData object and append the Blob
            const formData = new FormData();

            Object.entries(uploadUrlJson.fields).forEach(([key, value]) => {
                formData.append(key, value);
            });

            formData.append('file', blob, croppedImage.name); // Replace 'cropped_image.jpg' with the desired file name

            // Upload the FormData to the server using the obtained upload URL
            const uploadResponse = await fetch(uploadUrl, {
                method: 'POST',
                body: formData,
            });

            if (uploadResponse.ok) {
                console.log('Cropped image uploaded successfully.');
                displayAlert('success', 'Image updated successfully.');
                setImageToCrop(croppedImage)
                setOriginalImageUlr(uploadUrl + '/' + uploadUrlJson.fields.key)
                setImageDetails({...imageDetails, original_source_url: imageUrl})
            } else {
                console.error('Failed to upload cropped image.');
                displayAlert('error', 'Failed to upload image.');
            }
        } catch (error) {
            console.error('Error cropping image:', error);
            displayAlert('error', 'Error cropping image.');

        }
    };

    return (
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <div style={{width: '30%', maxWidth: '30%', textAlign: 'center', flex: '1', marginTop: '55px'}}>
                {
                    !!imageDetails.id &&
                    <>
                        <div style={{fontSize: '16px', marginBottom: '15px'}}>
                            <p><b>ID:</b> {imageDetails.id}</p>
                            <p><b>Upload
                                Date:</b> {imageDetails.upload_date ? new Date(imageDetails.upload_date).toLocaleString() : 'N/A'}
                            </p>
                            <p><b>Upload
                                User:</b> {imageDetails.upload_user_nickname ? imageDetails.upload_user_nickname : 'N/A'}
                            </p>
                            {imageDetails.statistics &&
                                <ImageStatisticsComponent statistics={imageDetails.statistics}/>}
                        </div>
                        <div style={{maxHeight: '500px', overflow: 'auto', marginLeft: '5px'}}>
                            <WikiImageList name={objectName} onImageClick={onImageClicked}/>
                        </div>
                    </>
                }
            </div>
            <div style={{textAlign: 'center', flex: '1', width: '55%', minWidth: '55%', marginRight: '40px'}}>
                <div className="image-container">
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        minHeight="70px"
                        marginLeft="15%"
                    >
                        <Typography variant="h4" component="div" textAlign="center">
                            {imageDetails.object_name}
                        </Typography>
                        {
                            !!imageDetails.object_name &&
                            <Tooltip title="Other Languages">
                                <IconButton aria-label="language" style={{marginTop: '14px', marginLeft: '10px'}}
                                            size="large"
                                            onClick={() => {
                                                setTranslateDialogOpen(true)
                                            }}>
                                    <TranslateIcon/>
                                </IconButton>
                            </Tooltip>
                        }
                        <TranslateDialog open={translateDialogOpen}
                                         onClose={() => {
                                             setTranslateDialogOpen(false)
                                         }}
                                         imageId={imageDetails.id}
                        />
                    </Box>
                    <div style={{
                        display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%',
                        marginBottom: '15px'
                    }}>
                        <div style={{display: 'flex', alignItems: 'center', width: '90%'}}>
                            <Select
                                value={objectSegment}
                                onChange={(e) => setObjectSegment(e.target.value)}
                                style={{width: '25%'}}>
                                <MenuItem value="people">People</MenuItem>
                                <MenuItem value="animals">Animals</MenuItem>
                            </Select>
                            <AutocompleteInput objectName={objectName} setObjectName={setObjectName}
                                               style={{
                                                   marginLeft: '15px',
                                                   width: '75%',
                                                   marginTop: '10px',
                                                   marginBottom: '10px',
                                               }}/>
                            <Tooltip title="Edit Name">
                                <IconButton aria-label="edit image info" style={{marginRight: '20px'}}
                                            onClick={handleEditClick}
                                            disabled={objectName === imageDetails.object_name &&
                                                objectSegment === imageDetails.object_segment}>
                                    <SaveAsIcon fontSize="large"/>
                                </IconButton>
                            </Tooltip>

                        </div>
                    </div>
                    <div style={{
                        width: '100%',
                        display: 'flex'
                    }}>
                        <div style={{width: '50%'}}>
                            <h3 style={{minWidth: '100%'}}>
                                Original Image
                            </h3>
                            <div style={{height: '350px', marginRight: '4px'}}>
                                <img
                                    onClick={() => {
                                        onUploadFileFromURL(originalImageUlr + '?' + Math.random())
                                        setImageUrl(imageDetails.original_source_url)
                                    }}
                                    src={originalImageUlr}
                                    alt={originalImageUlr}
                                    style={{maxHeight: '350px', maxWidth: '100%', 'cursor': 'pointer'}}
                                />
                            </div>
                        </div>
                        <div style={{width: '50%'}}>
                            <h3>
                                Edited Image
                            </h3>
                            <div style={{height: '350px', marginLeft: '4px'}}>
                                <ImageCropper
                                    key={imageToCrop}
                                    imageToCrop={imageToCrop}
                                    onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
                                />
                            </div>
                        </div>
                    </div>
                    <input style={{marginTop: '20px'}} type="file" accept="image/*" onChange={onUploadFile}/>
                    <div style={{display: 'flex', alignItems: 'center', width: '80%'}}>
                        <input
                            type="text"
                            placeholder="Enter Image URL"
                            value={imageUrl}
                            onChange={(e) => setImageUrl(e.target.value)}
                        />
                        <button onClick={onUploadUrl} style={{marginRight: '8px'}}>Retrieve Image</button>
                    </div>
                    <div className="upload-button">
                        <button onClick={onCropClick}
                                disabled={imageToCrop === null}>
                            <p>
                                Update Image
                            </p>
                            <FontAwesomeIcon icon={faEdit} style={{fontSize: '30px', padding: '8px'}}/>
                        </button>
                    </div>
                </div>
            </div>
            <div style={{
                justifyContent: 'center', textAlign: "center",
                width: '15%', marginRight: '10px', maxHeight: '800px', overflow: 'auto'
            }}>
                <h2>Similar Images</h2>
                <div>
                    {similarImages.map((similarImage) => (
                        <div key={similarImage.id} className="image-item">
                            <a href={`/edit-image/${similarImage.id}`}>
                                <img src={similarImage.internal_processed_image_url} alt={similarImage.object_name}
                                />
                                <p>
                                    {similarImage.object_name}
                                </p>
                                <p>
                                    {similarImage.distance.toFixed(2)}
                                </p>
                            </a>
                        </div>

                    ))}
                </div>
                <div style={{textAlign: 'center'}}>
                    <h2>Distant Images</h2>
                    <div>
                        {distantImages.map((distantImage) => (
                            <div key={distantImage.id} className="image-item">
                                <a href={`/edit-image/${distantImage.id}`}>
                                    <img src={distantImage.internal_processed_image_url}
                                         alt={distantImage.object_name}/>
                                    <p>
                                        {distantImage.object_name}
                                    </p>
                                    <p>
                                        {distantImage.distance.toFixed(2)}
                                    </p>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{width: '100%'}}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ImageDetails;
