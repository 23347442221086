import {callGameAPI} from "./apiUtils";

async function generateChallenge(game_id, region_code) {
    try {
        const data = await callGameAPI(region_code, `/${game_id}/challenge`, 'POST');
        // Alert the challenge code
        alert(`New challenge code: ${data.challenge_code}`);
        return data.challenge_code
    } catch (error) {
        console.error('Error generating challenge:', error);
        alert('Failed to generate challenge. Please try again.');
    }
}

export default generateChallenge;