// ChooseNameDialog.js
import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import AutocompleteInput from "./AutocompleteWikipedia";

const ChooseNameDialog = ({open, onClose, onSubmit}) => {
    const [name, setName] = useState('');

    const handleSubmit = () => {
        onSubmit(name);
        setName('')
    };

    const handleClose = (event) => {
        onClose();
        setName('');


    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle textAlign="center">Choose Name</DialogTitle>
            <DialogContent>
                <AutocompleteInput objectName={name} setObjectName={setName}
                                   style={{minWidth: '300px', width: '75%', marginTop: '10px', marginBottom: '10px'}}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChooseNameDialog;
