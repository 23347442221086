import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography} from '@mui/material';

const Leaderboard = ({challengeInfo}) => {
    const leaderboardData = challengeInfo.statistics.leaderboard_array;

    return (
        <>
            {leaderboardData.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table aria-label="leaderboard table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Rank</TableCell>
                                <TableCell>Player</TableCell>
                                <TableCell align="center">Steps</TableCell>
                                <TableCell align="center">Successful Games</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {leaderboardData.map((leader, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">{leader.rank}#</TableCell>
                                    <TableCell>{leader.nickname}</TableCell>
                                    <TableCell align="center">{leader.num_of_steps}</TableCell>
                                    <TableCell
                                        align="center">{leader.user_num_of_successful_games} / {leader.user_num_of_games}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="body1" color="textSecondary">
                    No leaderboard data available.
                </Typography>
            )}
        </>
    );
};

export default Leaderboard;
