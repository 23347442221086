import React, {useEffect, useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {Send} from '@mui/icons-material';
import {callGameEditorAPI} from "./apiUtils";
import ClearIcon from "@mui/icons-material/Clear";

const TranslateDialog = ({open, onClose, imageId}) => {
    const [languages, setLanguages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [submittingIndex, setSubmittingIndex] = useState(null);

    useEffect(() => {
        if (open) {
            fetchLanguages();
        }
    }, [open, imageId]);

    const fetchLanguages = async () => {
        setLoading(true);
        setError(null);
        setSuccessMessage(null);
        try {
            const data = await callGameEditorAPI(`/images/${imageId}/languages`, 'get');
            setLanguages(data);
        } catch (err) {
            setError('Failed to load languages');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (index, newValue) => {
        const updatedLanguages = [...languages];
        updatedLanguages[index].name_in_language = newValue;
        setLanguages(updatedLanguages);
        setSuccessMessage(null); // Reset success message on change
    };

    const handleSubmit = async (index) => {
        setSubmittingIndex(index);
        const newName = languages[index].name_in_language;
        const languageCode = languages[index].language_code;

        try {
            await callGameEditorAPI(`/images/${imageId}/languages/${languageCode}`, 'put', {name: newName});
            setSuccessMessage(`Successfully updated ${languages[index].language_name}`);
        } catch (err) {
            setError('Failed to update name');
        } finally {
            setSubmittingIndex(null);
        }
    };

    const handleDeleteLanguage = async (index) => {
        setSubmittingIndex(index);
        const languageCode = languages[index].language_code;

        try {
            await callGameEditorAPI(`/images/${imageId}/languages/${languageCode}`, 'delete');
            const updatedLanguages = [...languages];
            updatedLanguages[index].name_in_language = null;
            setLanguages(updatedLanguages);
            setSuccessMessage(`Successfully removed name in ${languages[index].language_name}`);

        } catch (err) {
            setError('Failed to remove language');
        } finally {
            setSubmittingIndex(null);
        }
    };

    const isButtonDisabled = (index) => {
        return !languages[index].name_in_language || submittingIndex !== null;
    };

    if (loading) {
        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>
                    <Typography variant="h6" align="center">Loading...</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container justifyContent="center" alignItems="center" style={{height: '100px'}}>
                        <CircularProgress/>
                    </Grid>
                </DialogContent>
            </Dialog>

        );
    }

    if (error) {
        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>{error}</DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    backgroundColor: '#f5f5f5', // Light grey background
                    borderRadius: '8px', // Rounded corners
                    padding: '20px', // Internal padding
                    width: '100%',
                },
            }}
        >
            <DialogTitle>
                <Typography variant="h6" align="center" sx={{marginRight: '12%'}}>Translate</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {languages.map((lang, index) => (
                        <Grid item xs={12} key={lang.language_id}>
                            <TextField
                                fullWidth
                                label={lang.language_name}
                                value={lang.name_in_language || ''}
                                onChange={(e) => handleChange(index, e.target.value)}
                                variant="outlined"
                                inputProps={{
                                    style: {textAlign: 'center'}
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                onClick={() => handleSubmit(index)}
                                                color="primary"
                                                size="small"
                                                startIcon={submittingIndex === index ? <CircularProgress size={20}/> :
                                                    <Send/>}
                                                disabled={isButtonDisabled(index)}>
                                                {submittingIndex === index ? 'Submitting...' : 'Submit'}
                                            </Button>
                                            <Tooltip title="Clear">
                                                <IconButton edge="end" color="primary"
                                                            disabled={languages[index].name_in_language === null}
                                                            onClick={async () => {
                                                                await handleDeleteLanguage(index)
                                                            }}>
                                                    <ClearIcon/>
                                                </IconButton>
                                            </Tooltip>


                                        </InputAdornment>
                                    )
                                }}
                                margin="normal" // Add margin for spacing
                            />
                        </Grid>
                    ))}
                </Grid>
                <Typography variant="body2" color="green" align="center" style={{marginTop: '10px', minHeight: '20px'}}>
                    {successMessage}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TranslateDialog;
